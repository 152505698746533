import styled from 'styled-components';
import tw from 'tailwind-styled-components';
import { NextImage } from './elements';

const TAGS_TO_REPLACE = [
  [/<b>/g, '<strong>'],
  [/<\/b>/g, '</strong>'],
  [/<i>/g, '<em>'],
  [/<\/i>/g, '</em>'],
];
const replaceTags = (str = '') => {
  return TAGS_TO_REPLACE.reduce((acc, [regex, replaceSrt]) => {
    return acc.replace(regex, replaceSrt);
  }, str);
};

const COMPONENTS = {
  list: ({ data }) => {
    const ListTag = data.style === 'unordered' ? UnorderListTag : OrderListTag;
    return (
      <ListTag>
        {data.items.map((item, i) => (
          <li key={i} dangerouslySetInnerHTML={{ __html: replaceTags(item) }} />
        ))}
      </ListTag>
    );
  },
  paragraph: ({ data }) => (
    <p dangerouslySetInnerHTML={{ __html: replaceTags(data.text) }} />
  ),
  header: ({ data }) => {
    const Tag = `h${data.level}`;
    return <Tag dangerouslySetInnerHTML={{ __html: replaceTags(data.text) }} />;
  },
  image: ({ data }) => (
    <figure>
      <NextImage media={data.file} />
      {data.caption && <figcaption>{data.caption}</figcaption>}
    </figure>
  ),
};

const EditorJSWrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--primary800);
    margin: 24px 0;
  }

  p {
    margin: 24px 0;
  }

  h1 {
    font-size: 1.8em;
  }

  a {
    color: var(--cta800);
    text-decoration: underline;
  }

  figure {
    margin: auto;
    width: fit-content;
    max-width: 600px;
  }

  figcaption {
    color: var(--gray700);
    text-align: right;
    font-size: var(--textS);
    line-height: var(--lineHeightS);
    font-style: italic;
  }
`;

const OrderListTag = tw.ol`
  flex
  flex-col
  gap-4
  list-decimal
  ml-4
`;

const UnorderListTag = tw.ul`
  flex
  flex-col
  gap-4
  list-disc
  ml-4
`;

const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch {
    return false;
  }
  return true;
};

export const EditorJSContent = ({ content = '{}' }) => {
  if (!isJsonString(content)) return content;
  const blocks = JSON.parse(content).blocks || [];
  if (!blocks) return null;
  return (
    <EditorJSWrapper>
      {blocks.map((block) => {
        const Component = COMPONENTS[block.type];
        if (!Component) return null;
        return <Component key={block.id} data={block.data} />;
      })}
    </EditorJSWrapper>
  );
};

export default EditorJSContent;
