import { gql } from "@apollo/client";
import { FilePartsFragment, LinkFragment } from "./fields";

export const AccordionItemFragment = () => gql`
  fragment AccordionItem on ComponentElementsAccordionItem {
    id
    title
    description
    descriptionRichText
  }
`;

export const BigCardMediaItemFragment = () => gql`
  ${FilePartsFragment()}
  fragment BigCardMediaItem on ComponentElementsBigCardMediaItem {
    id
    claim
    description
    iframe
    leftImage {
      ...FileParts
    }
    image {
      ...FileParts
    }
    mediaTestimony
  }
`;

export const CardHeaderFragment = () => gql`
  ${FilePartsFragment()}
  fragment CardHeader on ComponentElementsCardHeader {
    title
    hideIcon
    icon {
      ...FileParts
    }
  }
`;

export const CardItemFragment = () => gql`
  ${FilePartsFragment()}
  ${LinkFragment()}
  ${CardHeaderFragment()}
  fragment CardItem on ComponentElementsCardItem {
    id
    titleWithLink {
      ...Link
    }
    title
    description
    descriptionRichText
    featured
    imageFirst
    bigImage
    link {
      ...Link
    }
    image {
      ...FileParts
    }
    imageFit
    header {
      ...CardHeader
    }
    hideOnMobile
    showImageOnMobile
  }
`;

export const CardWithCtaFragment = () => gql`
  ${LinkFragment()}
  fragment CardWithCta on ComponentElementsCardWithCta {
    id
    title
    description
    cta {
      ...Link
    }
  }
`;

export const CheckListItemFragment = () => gql`
  fragment CheckListItem on ComponentElementsCheckListItem {
    text
  }
`;

export const FaqsQuestionFragment = () => gql`
  fragment FaqsQuestion on FaqsQuestions {
    id
    question
    answer
  }
`;

export const FooterCardGridFragment = () => gql`
  ${MiniCardWithLinkFragment()}
  ${CardWithCtaFragment()}
  fragment FooterCardGrid on ComponentElementsFooterCardGrid {
    miniCards {
      ...MiniCardWithLink
    }
    cards {
      ...CardWithCta
    }
  }
`;

export const GoogleReviewsItemFragment = () => gql`
  ${FilePartsFragment()}
  fragment GoogleReview on GoogleReviews {
    __typename
    id
    name
    fecha
    rating
    review
    profileImage {
      ...FileParts
    }
  }
`;

export const LinkGroupFragment = () => gql`
  ${LinkFragment()}
  fragment LinkGroup on ComponentElementsLinkGroup {
    id
    groupTitle
    childrenLinksColumnOne {
      ...Link
    }
    childrenLinksColumnTwo {
      ...Link
    }
  }
`;

export const ListItemFragment = () => gql`
  ${FilePartsFragment()}
  fragment ListItem on ComponentElementsListItem {
    id
    text
    hideIcon
    customIcon {
      ...FileParts
    }
  }
`;

export const MiniCardItemFragment = () => gql`
  ${FilePartsFragment()}
  fragment MiniCardItem on ComponentElementsMiniCardItems {
    id
    title
    tag
    featured
    hideOnMobile
    fullImage
    icon {
      ...FileParts
    }
    link {
      ...Link
    }
  }
`;

export const MiniCardItemNewFragment = () => gql`
  ${FilePartsFragment()}
  fragment MiniCardItemNew on ComponentElementsMiniCardItemsNew {
    id
    title
    description
    hideOnMobile
    icon {
      ...FileParts
    }
    link {
      ...Link
    }
  }
`;

export const MiniCardWithLinkFragment = () => gql`
  ${FilePartsFragment()}
  ${LinkFragment()}
  fragment MiniCardWithLink on ComponentElementsMiniCardWithLink {
    id
    title
    icon {
      ...FileParts
    }
    link {
      ...Link
    }
  }
`;

export const PathologiesItemFragment = () => gql`
  ${FilePartsFragment()}
  ${LinkFragment()}
  fragment PathologiesItem on ComponentElementsPathologiesItem {
    id
    image {
      ...FileParts
    }
    title
    description
    link {
      ...Link
    }
  }
`;

export const PersonDetailFragment = () => gql`
  ${FilePartsFragment()}
  fragment PersonDetail on ComponentElementsPersonDetail {
    profileImage {
      ...FileParts
    }
    name
    role
    companyTime
    testimony
    audio {
      ...FileParts
    }
  }
`;

export const PhoneFragment = () => gql`
  ${LinkFragment()}
  fragment ElementsPhone on ComponentElementsPhone {
    __typename
    id
    claim
    phone {
      ...Link
    }
  }
`;

export const PhoneContactFragment = () => gql`
  ${LinkFragment()}
  fragment PhoneContact on ComponentElementsPhoneContact {
    __typename
    id
    title
    cta {
      ...Link
    }
    description
  }
`;

export const PriceItemFragment = () => gql`
  ${ListItemFragment()}
  ${LinkFragment()}
  fragment PriceItem on ComponentElementsPriceItem {
    id
    title
    itemList {
      ...ListItem
    }
    price
    priceDescription
    button {
      ...Link
    }
  }
`;

export const ReputationCardFragment = () => gql`
  ${FilePartsFragment()}
  ${LinkFragment()}
  fragment ReputationCard on ComponentElementsReputationCard {
    id
    logoImage {
      ...FileParts
    }
    opinionMessage
    hideOnMobile
    link {
      ...Link
    }
  }
`;

export const StripeFragment = () => gql`
  ${FilePartsFragment()}
  fragment Stripe on ComponentElementsStripe {
    logo {
      ...FileParts
    }
    text
  }
`;

export const CardsFlexibleItemFragment = () => gql`
  ${FilePartsFragment()}
  ${LinkFragment()}
  fragment CardsFlexibleItem on ComponentElementsCardsFlexibleItem {
    id
    image {
      ...FileParts
    }
    icon {
      ...FileParts
    }
    bodyTitle
    bodyDescription
    button {
      ...Link
    }
    buttonType
  }
`;

export const DropDownListItemFragment = () => gql`
  fragment DropDownListItem on ComponentElementsDropDownListItem {
    id
    title
    body
  }
`;

export const HorizontalCardListItemFragment = () => gql`
  ${LinkFragment()}
  ${FilePartsFragment()}
  fragment HorizontalCardListItem on ComponentElementsHorizontalCardListItem {
    id
    title
    body
    cta {
      ...Link
    }
    image {
      ...FileParts
    }
  }
`;

export const TaskItemFragment = () => gql`
  ${FilePartsFragment()}
  fragment TaskItem on ComponentElementsTaskItem {
    id
    title
    body
    icon {
      ...FileParts
    }
  }
`;
export const DisplaySettingFragment = () => gql`
  fragment DisplaySetting on ComponentElementsDisplaySetting {
    __typename
    id
    displayMode
    smallBottomMargin
  }
`;

export const BlogItemFragment = () => gql`
  ${FilePartsFragment()}
  ${LinkFragment()}
  fragment BlogItem on ComponentElementsBlogItem {
    id
    title
    date
    link {
      ...Link
    }
    image {
      ...FileParts
    }
    hexCode
    hideOnMobile
  }
`;

export const LocationItemFragment = () => gql`
  ${FilePartsFragment()}
  ${LinkFragment()}
  fragment LocationItem on ComponentElementsLocationItem {
    id
    title
    mobileTitle
    image {
      ...FileParts
    }
    link {
      ...Link
    }
    hideOnMobile
    showImageOnMobile
  }
`;

export const MapPinFragment = () => gql`
  fragment MapPin on ComponentElementsMapPin {
    __typename
    id
    name
    placeId
    lat
    lng
  }
`;